import { Box, Flex, HStack, Input, InputGroup, Select, InputLeftAddon, Text, Textarea, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import BrandButton from "../../../components/brandButton/BrandButton";
import { CustomToast } from "../../../components/toast/CustomToast";
import { useData } from "../../../data";
import { mutateFunction, useMutation } from "../../../libs/apis";
import IsDesktop from "../../../utils/IsDesktop";


const AddOffer = ({ onToggle, refetch }) => {
  const isDesktop = IsDesktop();
  const { data } = useData(`brands`);
  const [offerData, setOfferData] = useState({});
  const [brands, setBrands] = useState([]);
  const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
  const {addToast} = CustomToast();
  const addStartupOffer = () => {
    mutate(
        { key: `offers`, method: "post", data: offerData },
        {
          onSuccess(res) {
            addToast(res)
            setOfferData();
            refetch();
            setTimeout(() => {
              onToggle();
            }, 2000)
          },
        }
      );
  }

  const handleChange = (e) => {
    if (e.target.name === "brand_id"){
        setOfferData((prev) => ({
            ...prev,
            [e.target.name]: Number(e.target.value)
          }));
    } else{
        setOfferData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
    }
  };

  useEffect(() => {
    if (data?.data) {
      setBrands(data?.data?.brands);
    }
  }, [data]);

  return (
    <VStack alignItems="flex-start">
      <Flex justifyContent="flex-start" alignItems="center" my={4} mx={4}>
        <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
          <Box mr={2}>
            <HiOutlineArrowNarrowLeft size="35px" />
          </Box>
          <Box>
            <Text fontWeight={600}>Back</Text>
          </Box>
        </Flex>
      </Flex>
      <HStack h="500px" w="100%" alignItems="flex-start" m={4}>
        <VStack w="60%" alignItems="flex-start">
          <Flex flexDir="column" w="100%" key="title">
            <Text fontSize="14px" mb={2}>
              Offer Title
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer Title" name="title" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
          <Flex flexDir="column" w="100%" key="brand">
            <Text fontSize="14px" mb={2}>
              Select Brand
            </Text>
            <Select borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" placeholder="Brand" name="brand_id" onChange={ handleChange}>
              {brands?.map((eachBrand) => (
                <option key={eachBrand.id} value={eachBrand.id}>
                  {eachBrand.name}
                </option>
              ))}
            </Select>
            
          </Flex>
          <Flex flexDir="column" w="100%" key="value">
            <Text fontSize="14px" mb={2}>
              Offer Price
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer price" name="price" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
          {/* <Flex flexDir="column" w="100%" key="price">
            <Text fontSize="14px" mb={2}>
              Offer Value
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer value" name="offer_value" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex> */}
          <Flex flexDir="column" w="100%" key="offer_url">
            <Text fontSize="14px" mb={2}>
              Offer URL
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer url" name="offer_url" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
          <Flex flexDir="column" w="100%" key="caption">
            <Text fontSize="14px" mb={2}>
              Offer Caption
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer Caption" name="caption" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
          <Flex flexDir="column" w="100%">
            <HStack spacing={isDesktop ? 2 : 0} flexDir={isDesktop ? "row" : "column"} justifyContent="space-between" alignItems="center" w="100%">
              <Flex flexDir="column" w="100%" key="start_date">
                <Text fontSize="14px" mb={2}>
                  Start Date
                </Text>
                <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="start_date" type="date" size="md" onChange={(e) => handleChange(e)} />
              </Flex>
              <Flex flexDir="column" w="100%" key="end_date">
                <Text fontSize="14px" mb={2}>
                  End Date
                </Text>
                <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="end_date" type="date" size="md" onChange={(e) => handleChange(e)} />
              </Flex>
            </HStack>
          </Flex>
          <BrandButton isLoading={mutateIsLoading} text="Add Offer" w="120px" colorScheme="blue" onClick={()=>addStartupOffer()} />
        </VStack>
        <Flex flexDir="column" w="38%" h="100%" key="details" mx={4}>
          <Text fontSize="14px" mb={2}>
            Offer Details
          </Text>
          <Textarea borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer Details" name="description" type="text" h="100%" w="100%" onChange={(e) => handleChange(e)} />
        </Flex>
      </HStack>
    </VStack>
  );
};
export default AddOffer;
