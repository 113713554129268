import { Flex, Box, Text, Spacer, Image, useDisclosure } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";
import getRandomInt from "../../../utils/getRandomInt";
import OpenExternalAdvertLinkModal from "../../../components/modal/OpenExternalAdvertLinkModal";


const AdvertFeatureAlt = ({
  adverts = []
}) => {
  const navigate = useNavigate();
  const {
    isOpen : isAdvertLinkOpen,
    onOpen : onOpenAdvertLink,
    onClose : onCloseAdvertLink
  } = useDisclosure()
  const [count, setCount] = useState(0)
  const [link, setLink] = useState('')
  const intervalId = useRef(null)

  function resetTimeout() {
    if (intervalId.current) {
      clearTimeout(intervalId.current);
    }
  }

  const handleAdvertClick = (link) => {
    // link && window.open(link, '_blank')
    if(link){
      setLink(link)
      onOpenAdvertLink()
    }
  };

  const onClose = () => {
    onCloseAdvertLink()
    setLink('')
  }

  useEffect(() => {
      resetTimeout()
      intervalId.current = setTimeout(() => {
          setCount(prev => prev === adverts?.length - 1 ? 0 : prev + 1)
      }, 3000)

      return () => { resetTimeout() }
  }, [count, adverts?.length])

  return (
    <Box w="full">
      <OpenExternalAdvertLinkModal isOpen={isAdvertLinkOpen} onClose={onClose} link={link} />

      {
                  adverts?.length > 0 ? (
                    <Box className="items-start gap-x-10 gap-y-3 justify-between my-5 w-full flex-wrap">
                      <Box className="relative w-full max-w-[695px] overflow-hidden">
                      {
                              adverts?.map((advert, index) => {
                                return (
                                  <div onClick={() => {handleAdvertClick(advert?.external_link)}} key={index} className="w-full inline-block">
                                    <img className="w-full max-w-[695px] rounded-lg object-cover aspect-[1.78]" src={advert?.media} alt={advert?.title} srcset="" />
                                  </div>
                                )
                              })
                      }  
                      </Box>
                    </Box>
                  ) : null 
                }
    </Box>
  );
};

export default AdvertFeatureAlt;
