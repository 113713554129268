import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  ButtonGroup, 
  Flex, Icon, 
  Image, Text, 
  useDisclosure, 
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import Header from "../../../components/header/Header";
import calendar from "../../../public/icons/calendar.svg";
import plus from "../../../public/icons/plus.svg";
import newplus from "../../../public/icons_v2/add-circle.svg";
import love from "../../../public/icons_v2/lovely.svg";
import eventarrow from "../../../public/icons/eventarrow.svg";
import ExpertTable from "../../../components/table/ExpertTable";
import TableAction from "../../../components/table/action/TableAction";
import useData from "../../../data/useData";
import Calendar from "../../../components/calendar/Calendar";
import CalendarDrawer from "../../../components/drawer/calendar/CalendarDrawer";
import { upcoming_bookings, pending_bookings, past_bookings } from "./bookings";
import IsDesktop from "../../../utils/IsDesktop";
import BookingModal from "../../../components/modal/BookingModal";
import moment from "moment";
import DailyBookingsCalendar from "./DailyBookingsCalendar";
import BrandButton from "../../../components/brandButton/BrandButton";
import { AiOutlineCalendar } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewDailyBookingsCalendar from "./NewDailyBookingsCalendar";
import ExpertBookingChart from "../../../components/charts/expertBookings";
import { BASE_URL } from "../../../helpers/constant";
import client from "../../../libs/client";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const tableColumns = [
  {
    key: "profile",
    title: "Founder / Booking",
  },
  {
    key: "date",
    title: "Date",
  },
  {
    key: "start_time",
    title: "Time",
  },

  {
    key: "actions",
    title: "Status",
  },

];

const buttonGroup = [
  {
    value: "upcoming",
    title: "Upcoming",
  },
  {
    value: "past",
    title: "Past",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const { data, isLoading, refetch } = useData(`bookings`);
  const [bookings, setBookings] = useState([]);
  const isDesktop = IsDesktop();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const params = useParams()
  const location = useLocation()
  const [chartData, setChartData] = useState({
    months : [],
    data: []
  })
  const [difference, setDifference] = useState(0)

  useEffect(() => {
    if (data?.data) {
      switch (activeIndex) {
        case 0:
          setBookings(data?.data?.bookings?.filter((booking) => moment(booking?.slot_start_date).isSameOrAfter(moment(), 'days')));
          break;
        case 1:
          setBookings(data?.data?.bookings?.filter((booking) => moment(booking?.slot_start_date).isBefore(moment(), 'days')));
          break;
        default:
          setBookings(data?.data?.bookings?.filter((booking) => moment(booking?.slot_start_date).isSameOrAfter(moment(), 'days')));
      }
    }
  }, [activeIndex, data]);

  useEffect(() => {
    if(location?.search?.includes("?slot=open")){
      if(!isOpenModal)onOpenModal()
    }
  }, [location?.search])

  useState(() => {
    const bookingFigures = []
    const bookingMonths = []
    let currentMonth = 0
    let prevMonth = 0
    return client().get(`${BASE_URL}experts/monthly-total`).then((res) => {
      const monthlyTotal = res?.data || {}
      if(monthlyTotal?.data){
        for(let i = 5; i >= 0; i--){
          const month = moment().subtract(i, 'month').format("MMM")
          if(i == 0) currentMonth = monthlyTotal.data.bookings[month] || 0
          if(i == 1) prevMonth = monthlyTotal.data.bookings[month] || 0
          bookingMonths.push(month)
          monthlyTotal.data.bookings[month] ? bookingFigures.push(monthlyTotal.data.bookings[month]) : bookingFigures.push(0)
        }
  
        const percentageDiff = currentMonth == 0 && prevMonth == 0 ? 0 : currentMonth == 0 ? -1 : prevMonth == 0 ? 1 : (currentMonth - prevMonth)/prevMonth
        setDifference(percentageDiff)
        setChartData({
          months: bookingMonths,
          data: bookingFigures
        })
      }
    });
    
  }, [])


  useEffect(() => {
  }, [chartData])


  const mapBookings = (bookings = []) => {
    return bookings.map((booking, i) => ({
      ...booking,
      "": <TableAction />,
    }));
  };

  return (
    <>
      <BookingModal isOpen={isOpenModal} onClose={onCloseModal} />
      {isDesktop && <CalendarDrawer isOpen={isOpen} onClose={onClose} />}
      <Box>
        <Header title="Dashboard" />
        <Flex
        className={`flex-col ${
          isDesktop ? "max-h-[95vh] pb-10 overflow-y-scroll" : ""
        }`}
      >
          <Flex h={isDesktop ? "100%" : "auto"} minHeight={"120vh"} flexDir={!isDesktop ? "column" : "row"}>
            <Box w={!isDesktop ? "100%" : "65%"} mb={4} p={isDesktop ? 6 : 2} px={isDesktop ? 8 : 4} className="flex flex-col w-full">

              <Box className="bg-white border p-4 pl-5 rounded-lg">

                  <Box className="w-full">
                      <Flex justify="space-between" align="center" p={4}>
                          <Box className="flex flex-col">
                            <Text className="text-gray-600 text-sm">
                              Total Bookings
                            </Text>
                            <Box className="flex gap-2 items-center">
                              <Text className="text-3xl font-semibold">
                                {bookings.length}
                              </Text>
                              <Box className={`flex gap-1 items-center rounded-lg p-[2px] border text-[9px] ${difference > 0 ? 'text-green-700 bg-green-100' : 'text-red-700 bg-red-100'}`}>
                                {
                                  difference == 0 ? null : difference > 0 ? <FaArrowUp /> : <FaArrowDown />
                                }
                                <Text className="font-medium">{difference * 100}%</Text>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="flex flex-row" w="250px" justifyContent="space-between">
                            <Tooltip label="Add a new slot" placement="bottom" hasArrow bg='brand.primary'>
                              <Box>
                                <BrandButton color="#197FC6" style={{border: '1px solid #197FC6'}} className="border border-green-200" icon={newplus} text="Add Slot" onClick={onOpenModal} />
                              </Box>
                            </Tooltip>
                            <Tooltip label="View your created slots" placement="bottom" hasArrow bg='brand.primary'>
                              <Box>
                                <BrandButton color="#197FC6" style={{border: '1px solid #197FC6'}} icon={love} text="My Slots" onClick={() => navigate("../account-settings?tab=slots")} />
                              </Box>
                            </Tooltip>
                          </Box>
                      </Flex>
                    </Box>
                  {
                    chartData.months.length ? (
                      <ExpertBookingChart labels={chartData?.months || ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']} bookingValues={chartData?.data} max={chartData?.data ? Math.max(...chartData.data) + 50 : 50} />
                    ) : (<Box>
                      <Spinner />
                    </Box>)
                  }
                  </Box>
              <Box>
                {/* <Flex  align="center" justify="space-between" mt={8}>
                  <Text color="brand.primary" fontWeight={700} fontSize={14}>
                    Bookings
                  </Text>
                  <ButtonGroup isAttached variant="outline" size="sm" boxShadow="0px 0px 8px 4px rgba(19, 74, 112, 0.1)">
                    {buttonGroup?.map((button, index) => (
                      <Button
                        color={activeIndex === index ? "#fff" : "brand.primary"}
                        key={index}
                        onClick={() => {
                          setActiveIndex(index);
                        }}
                        bg={activeIndex === index ? "brand.primary" : "transparent"}
                        _hover={{ bg: activeIndex === index ? "brand.primary" : "#eee" }}
                      >
                        {button.title}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Flex> */}

                <ExpertTable listType={activeIndex === 0 ? 'upcoming': 'past'} header={tableColumns} data={mapBookings(bookings)} isLoading={isLoading} borderRadius="10px" refetch={refetch}/>
              </Box>
            </Box>

            <Box w={!isDesktop ? "100%" : "35%"} boxShadow="10px 10px 20px rgba(54, 171, 190, 0.05)" borderRadius="10px 10px 0px 0px" p={2}  pt={12}>
              <NewDailyBookingsCalendar />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Dashboard;
